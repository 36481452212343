import * as React from "react";
import "./../../App.css"
import { Tabs, Tab } from "@blueprintjs/core";
import styled from "styled-components";
import { Editor } from "./Editor";
import { editorContext, EditorState } from "./EditorState";
import { ItemDatabase } from "../../components/ItemDatabase/ItemDatabase";

interface AdminPageBaseProps {
    className?: string;
}

export const AdminPageBase: React.FC<AdminPageBaseProps> = (props) => {
    const [tabState, setTabstate] = React.useState<React.ReactText>("1");

    return <div className={`App ${props.className}`}>
            <Tabs id="TabsExample" onChange={(newTab) => setTabstate(newTab)} selectedTabId={tabState}>
                <Tab id="1" title="Itemdatabase" panel={
                    <ItemDatabase/>
                } />
                <Tabs.Expander />
                <Tab id="2" title="Editor" panel={
                    <editorContext.Provider value={new EditorState()}>
                        <Editor x={8} y={8} />
                    </editorContext.Provider>
                } />
                <Tabs.Expander />
            </Tabs>
    </div>
}

export const AdminPage = styled(AdminPageBase)`
    padding: 8px;
`;