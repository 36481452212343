import { Avatar, Box, Button, ButtonGroup, Container, Fab, Grid, Paper, Typography } from "@material-ui/core";
import { orange, red } from "@material-ui/core/colors";
import * as React from "react";
import { Link as RouteLink, useHistory } from "react-router-dom"
import { useStyles } from "../Dashboards/Dashboard";

export const Landingpage: React.FC = () => {
    const classes = useStyles();
    const routeHistory = useHistory();
    return <div style={{ margin: "auto", marginTop: 64 }}>
                    <Container className="fade-in">
                        <div className="glass" style={{ padding: "64px 0px 64px 0px" }}>
                            <Avatar style={{ margin: "auto", width: 256, height: 256, border: "1px solid transparent", background: red[800], boxShadow: "0px 0px 8px rgb(121 52 52 / 68%)" }} src="https://media.discordapp.net/attachments/576435694785003520/682661860163846164/Comic3.png?width=256&height=256"/>
                            <div style={{ margin: "auto", textAlign: "center" }}>
                                <Grid direction="column" container spacing={3}>
                                    <Grid item>
                                        <div className="glassPanel">
                                            <Grid container spacing={3} direction="column">
                                                <Grid item>
                                                    <Typography component="h1" variant="h4" color="textPrimary" noWrap className={classes.title}>
                                                        Nycaria
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography component="div" variant="body1" color="textSecondary" noWrap className={classes.title}>
                                                        <p style={{ margin: "auto", color: "initial" }}>
                                                            Hey, wie kann ich dir behilflich sein?
                                                        </p>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <Fab color="secondary" variant="extended" href="https://discord.com/api/oauth2/authorize?client_id=657653476523900938&permissions=2419453008&scope=applications.commands%20bot">
                                            Lade mich einfach auf deinen Server ein!
                                        </Fab>
                                    </Grid>
                                    <Grid item/>
                                    <Grid item>
                                        <ButtonGroup>
                                            <Button onClick={() => routeHistory.push("/commands")} color="secondary" variant="contained">
                                                Commands
                                            </Button>
                                            <Button onClick={() => window.open("https://discord.gg/n7MxcUUm7c", "_blank")} color="secondary" variant="contained">
                                                Support
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>    
                            </div>
                        </div>
                    </Container>
                </div>
}