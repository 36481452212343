export enum ViewState {
    Base=0,
    Titlepack,
    Grinder,
    Background,
    FinalScreen
}

export interface BaseItem {
    name: string;
    evId: number;
    rarity: number;
    description: string;
    limit: number;
    price: number;
    inShop: boolean;
    subType: string;
    tradeable: boolean;
}

export interface TitlePackItem {
    raritySettings: string;
    items: string[];
}

export interface ItemGrinder {
    amount: number;
    grindedItemId: number;
}

export interface Background {
    imageUrl: string;
}


