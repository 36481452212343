
import * as React from "react";

import { makeObservable, observable, action, computed } from "mobx";
import { BaseItem, TitlePackItem } from "./Types";









export const DatabaseContext = React.createContext<CreateItemFormState>(null);

export class CreateItemFormState {
    public baseItem: BaseItem;
    public titlePackItem: TitlePackItem;
    public current = 0;
    public grindAmount = 0;
    public selectedKeys: string[] = [];
    public targetKeys: string[] = [];
    public raritySettings: string = '{"0":2000,"1":1000,"2":1000,"3":1000,"4":1000}';
    public submitting = false;
    public grindItem: number;
    public isFinished = false;
    public progress = 0;


    constructor() {
        makeObservable(this, {
            baseItem: observable,
            current: observable,
            selectedKeys: observable,
            targetKeys: observable,
            raritySettings: observable,
            titlePackItem: observable,
            submitting: observable,
            grindAmount: observable,
            grindItem: observable,
            isFinished: observable,
            progress: observable,

            setBaseItem: action,
            setCurrent: action,
            setSelectedKeys: action,
            setTargetKeys: action,
            setRaritySettings: action,
            setTitlePackItem: action,
            setSubmitting: action,
            setGrindItem: action,

            rarity: computed,
        });
    }

    public get rarity() {
        return this.calculateRarity(this.raritySettings)
    }

    public calculateRarity(str: string) {
        try {
            const set = JSON.parse(str);

            let max = 0;
            let len = 0;
            for (const i in set) {
                max += set[i];
                len++;
            }

            const res = []
            for (let i in set) {
                res.push({
                    key: i,
                    chance: set[i] / (max / 100)
                })
            }
            return res;
        } catch (err) {
            return [];
        }
    }

    public setBaseItem(item: BaseItem) {
        this.baseItem = item;
    }

    public setProgress(int: number) {
        this.progress = int;
    }

    public setFinished(bool: boolean) {
        this.isFinished = bool;
    }

    public setGrindAmount(int: number) {
        this.grindAmount = int;
    }

    public setGrindItem(id: number) {
        this.grindItem = id;
    }

    public setCurrent(int: number) {
        this.current = int;
    }

    public setSelectedKeys(str: string[]) {
        this.selectedKeys = str;
    }

    public setTargetKeys(str: string[]) {
        this.targetKeys = str;
    }

    public setRaritySettings(str: string) {
        this.raritySettings = str;
    }

    public setTitlePackItem(item: TitlePackItem) {
        this.titlePackItem = item;
    }

    public setSubmitting(bool: boolean) {
        this.submitting = bool;
    }
}