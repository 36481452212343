import { gql } from "@apollo/client";

export const ME = gql`
  {
      myInventory {
        id
        item {
            name
            rarity
        }
      }
      me {
          id
          userId
          note
          switchfc
          exp
          money
          note
          activeTitle {
            id
            item {
              name
              rarity
            }
          }
      }
  }
`;

export const SHOP = gql`
{
  shop {
    id
    name
    rarity
    price
    subType
    description
  }
}

`
export const COMMANDS = gql`
{
  commands {
    name,
    description,
    path,
    description,
    premium,
    module,
    syntax
  }
}

`

export const ITEMS = gql`
  query ITEMS($page: Float!) {
    items(page: $page, size: 10) {
      id,
      description,
      name,
      rarity,
      limit,
      evId,
      price,
      subType,
      tradeable,
      inShop
    }
  }
`;

export const NEW_ITEM = gql`
  mutation CreateItem($data: ItemInput!) {
    createItem(data: $data) {
      id,
      description,
      name,
      rarity,
      limit,
      evId,
      price,
      subType,
      tradeable,
      inShop
    }
  }
`;

export const NEW_GRINDER = gql`
  mutation CreateGrinder($data: GrinderInput!) {
    createGrinder(data: $data) {
      id
    }
  }
`;

export const NEW_TITLE = gql`
  mutation CreateTitle($data: TitleInput!) {
    createTitle(data: $data) {
      id
    }
  }
`;

export const SET_SHOP = gql`
  mutation SetShopState($id: Int!, $inShop: Boolean!) {
    setShopState(id: $id, inShop: $inShop) {
      id,
      inShop
    }
  }
`;

export const NEW_TITLEPACK = gql`
  mutation CreateTitlePack($data: TitlePackInput!) {
    createTitlepack(data: $data) {
      id
    }
  }
`;

export const NEW_BACKGROUND = gql`
  mutation CreateBackground($data: BackgroundInput!) {
    createBackground(data: $data) {
      id
    }
  }
`;


export const INVENTORY = gql`
  query INV($id: Float!) {
    getInventory(id: $id) {
      id
        item {
            name
            rarity
        }
    }
  }
`

export const TITLEPACK = gql`
  query TITLEPACK($id: Float!) {
    getTitlepack(id: $id) {
      id
      raritySettings
      items {
        id,
      }
    }
  }
`

export const TITLEPACKS = gql`
  query TITLEPACKS($page: Float!) {
    getTitlepacks(page: $page) {
      id,
      raritySettings,
      item {
        name,
        rarity,
        inShop,
        price,
        description
      }
      items {
        id,
        name,
        rarity,
      }
    }
  }
`

export const profileBackgrounds = gql`
  query BACKGROUNDS($page: Float!) {
    profileBackgrounds(page: $page) {
      id,
      imageUrl
      item {
        name,
        rarity
      }
    }
  }
`