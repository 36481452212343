import React from "react";
import { Overlay, Classes, Tabs, Tab } from "@blueprintjs/core";
import { Button } from "antd";
import { observer } from "mobx-react";
import { editorContext, EditorMode } from "./EditorState";
import { ButtonGroup } from "react-bootstrap";
import { action } from "mobx";

export enum FieldType {
    NOTHING = 0,
    HOUSE,
    AGENT,
    START,
    GOAL,
    BLOCKED,
    PATH,
    SKYSCRAPER,
    LAWN,
    ITEMCHEST

}

const options = [{
    label: "Border",
    value: FieldType.HOUSE,
    color: "black"
}, {
    label: "None",
    value: FieldType.NOTHING,
    color: "ghostwhite"
}, {
    label: "Goal",
    value: FieldType.GOAL,
    color: "green"
}, {
    label: "Spawn",
    value: FieldType.START,
    color: "yellow"
}, {
    label: "NPC",
    value: FieldType.SKYSCRAPER,
    color: "lightgrey"
}, {
    label: "Itemchest",
    value: FieldType.ITEMCHEST,
    color: "tomato"
},]

const optionsMode = [{
    label: "DRAW",
    value: EditorMode.DRAWING,
    color: "black"
}, {
    label: "EDITING",
    value: EditorMode.EDITING,
}]


// constants
const tileSize = 50;
interface IEditor2Props {
    onChange: (y: number, x: number, type: FieldType) => void;
    cb: (func: () => void) => void;
}

export const Grid: React.FC<IEditor2Props> = observer((props) => {
    const canvas = React.useRef<HTMLCanvasElement>();
    const pageStore = React.useContext(editorContext);


    const line = (x0, y0, x1, y1, size = tileSize) => {
        let dx = Math.abs(x1 - x0);
        let dy = Math.abs(y1 - y0);
        let sx = (x0 < x1) ? 1 : -1;
        let sy = (y0 < y1) ? 1 : -1;
        let err = dx - dy;

        while (true) {
            draw(pageStore.editorState, x0 * size, y0 * size, size, size);
            pageStore.changes.set(`${y0}|${x0}`, { y: y0, x: x0 });
            if ((x0 === x1) && (y0 === y1)) break;
            let e2 = 2 * err;
            if (e2 > -dy) {
                err -= dy; x0 += sx;
            }
            if (e2 < dx) {
                err += dx; y0 += sy;
            }
        }
    }

    const handleMouse = (e: MouseEvent) => {
        let x;
        let y;




        if (e.pageX || e.pageY) {
            x = e.pageX;
            y = e.pageY;
        }
        else {
            x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }
        x -= canvas.current.offsetLeft;
        y -= canvas.current.offsetTop;
        y = Math.floor(y / tileSize);
        x = Math.floor(x / tileSize);

        if (pageStore.drawing) {
            if (pageStore.editorMode === EditorMode.DRAWING) {
                try {
                    if (pageStore.pos) {
                        let oldPos = [pageStore.pos[0], pageStore.pos[1]];
                        pageStore.pos = [y, x];
                        line(oldPos[1], oldPos[0], pageStore.pos[1], pageStore.pos[0]);
                    } else {
                        pageStore.pos = [y, x];
                        draw(pageStore.editorState, x * tileSize, y * tileSize, tileSize, tileSize);
                    }
                } catch (err) { }
            }
        } else {
            updateMap();
            pageStore.ctx.strokeStyle = "red";
            pageStore.ctx.strokeRect(x * tileSize, y * tileSize, tileSize, tileSize);
            pageStore.ctx.strokeStyle = "";
        }
    }



    const togglePaint = (e: MouseEvent) => {
        if (pageStore.drawing) {
            pageStore.changes.forEach(e => {
                props.onChange(e.y, e.x, pageStore.editorState);
            });
            pageStore.changes.clear();
            updateMap();
            pageStore.forceUpdate();
            pageStore.pos = null;

            if(pageStore.editorMode === EditorMode.EDITING) {
                let x;
                let y;
                if (e.pageX || e.pageY) {
                    x = e.pageX;
                    y = e.pageY;
                }
                else {
                    x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                    y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
                }
                x -= canvas.current.offsetLeft;
                y -= canvas.current.offsetTop;
                y = Math.floor(y / tileSize);
                x = Math.floor(x / tileSize);
                pageStore.setActiveTile([x, y])
                updateMap();
            }
        };
        pageStore.drawing = !pageStore.drawing;

    }

    const draw = (type: FieldType, x, y, width, height) => {
        let color = options.find(e => e.value === type).color
        pageStore.ctx.fillStyle = color;
        pageStore.ctx.fillRect(x, y, width, height);
        pageStore.ctx.fillStyle = "";
    }

    const updateMap = () => {
        if (pageStore.ctx) {
            pageStore.ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
            pageStore.mapData[pageStore.mapPage].forEach((e, yindex) => e.forEach((p, xindex) => {
                draw(p, xindex * tileSize, yindex * tileSize, tileSize, tileSize);
            }));
            if(pageStore.activeTile[0] && pageStore.activeTile[1]) {
                pageStore.ctx.strokeStyle = "black";
                pageStore.ctx.strokeRect(pageStore.activeTile[0] * tileSize, pageStore.activeTile[1] * tileSize, tileSize, tileSize);
            }
            
            pageStore.ctx.strokeStyle = "tomato";
            pageStore.mapLinks.forEach(link => {
                link.forEach(soloField => {
                    if(soloField.map === pageStore.mapPage) {
                        pageStore.ctx.strokeRect(soloField.pos[0] * tileSize, soloField.pos[1] * tileSize, tileSize, tileSize);
                    }
                });
                if(link[0].map === pageStore.mapPage || link[1].map === pageStore.mapPage) {
                    if(link[0].map === link[1].map) {
                        // line(link[0].pos[0], link[0].pos[1], link[1].pos[0], link[1].pos[1], 5)
    
                        pageStore.ctx.beginPath();
                        pageStore.ctx.moveTo(link[0].pos[0] * tileSize + tileSize/2,  link[0].pos[1] * tileSize  + tileSize/2);
                        pageStore.ctx.lineTo(link[1].pos[0] * tileSize  + tileSize/2, link[1].pos[1] * tileSize  + tileSize/2);
                        pageStore.ctx.stroke();
                    } else if (link[0].map === pageStore.mapPage) {
                        pageStore.ctx.font = "9px Arial";
                        pageStore.ctx.fillText(`TO: ${JSON.stringify(link[1])}`, link[0].pos[0] * tileSize - 5,  link[0].pos[1] * tileSize  + tileSize/2);
                    } else if (link[1].map === pageStore.mapPage) {
                        pageStore.ctx.font = "9px Arial";
                        pageStore.ctx.fillText(`TARGET`, link[0].pos[0] * tileSize + tileSize/4,  link[0].pos[1] * tileSize  + tileSize/2);
                    }
                }
            })
            pageStore.ctx.strokeStyle = "";
        } else {
            console.log(":(")
        }
    }

    React.useEffect(() => {
        pageStore.ctx = canvas.current.getContext("2d");
        canvas.current.addEventListener("mousemove", handleMouse, false);
        canvas.current.addEventListener("mousedown", togglePaint, false);
        canvas.current.addEventListener("mouseup", togglePaint, false);
        updateMap()
        props.cb(updateMap);
    }, []);

    return <>
        <canvas ref={canvas} width="800" height="800" style={{ border: '1px solid black' }} id="editor"></canvas>
    </>;
})




// -> This is the data we want to show
interface IEditorProps {
    map?: boolean;
    x?: number;
    y?: number;
}

const brush = [{
    type: "1x1",
    value: 1
}];


export const Editor: React.FC<IEditorProps> = observer((props) => {
    const [mapLoader, setMapLoader] = React.useState([]);

    const [matrixInput, setMatrixInput] = React.useState("");
    const [showImport, setShowImport] = React.useState(false)
    const [showCode, setShowCode] = React.useState(false)
    const pageStore = React.useContext(editorContext);
    const [update, setUpdate] = React.useState<() => void>(() => { })


    function updateMap() {
        update();
        pageStore.forceUpdate();
    }

    function resetField(type: FieldType) {
        pageStore.mapData[pageStore.mapPage].forEach((e, y) => {
            e.forEach((p, x) => {
                if (p === type) {
                    pageStore.setMapData(y, x, FieldType.NOTHING);
                };
            });
        });
    }

    const onChangeData = (y: number, x: number, type: FieldType) => {
        // there can only be one agent, one start and one goal
        if ([FieldType.START].includes(type)) {
            resetField(type);
        }
        replaceTiles(FieldType.PATH, FieldType.NOTHING, pageStore.mapData[pageStore.mapPage]);
        pageStore.setMapData(y, x, type);
    }

    function replaceTiles(oldTile: FieldType, newTile: FieldType, map: FieldType[][]) {
        map.forEach((e, y) => {
            e.forEach((p, x) => {
                if (p === oldTile) {
                    map[y][x] = newTile;
                }
            })
        })
        return map;
    }

    if (props.map) return <Grid cb={setUpdate} onChange={() => {  }} />
    return (
        <>
            <Overlay className={Classes.OVERLAY_SCROLL_CONTAINER} isOpen={showImport} onClose={() => setShowImport(false)}>
                <div style={{ background: "ghostwhite", width: "400px", height: "400px" }}>
                    <textarea value={matrixInput} onChange={e => setMatrixInput(e.target.value)} />
                    <Button onClick={() => {
                        let mapData = JSON.parse(matrixInput)
                        mapData.forEach((y, yi) => {
                            y.forEach((x, xi) => {
                                onChangeData(yi, xi, x);
                            })
                        })
                        updateMap();
                    }}>
                        Import
                    </Button>
                </div>
            </Overlay>
        Edit Type: {pageStore.editorState} <br />
            <h4>EDITOR MDDE {pageStore.editorMode}</h4> <br />
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="side">
                    <div className="btn" style={{ marginTop: "16px" }} onClick={pageStore.logMapData}>show in console</div>

                    <h4>Brush</h4>
                    <select style={{ width: "100%" }} onChange={e => pageStore.brush = parseInt(e.target.value)}>
                        {brush.map(e => <option value={e.value}>{e.type}</option>)}
                    </select>

                    <h4>EDITOR MDDE</h4>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {optionsMode.map(e => <Button style={{ border: `2px solid ${e.color}` }} key={e.value} ghost={pageStore.editorMode === e.value} onClick={() => {
                            pageStore.setEditorMode(e.value);
                        }} >{e.label}</Button>)}
                    </div>
                    {/* Tileselect */}
                    <h4>Tiles</h4>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {options.map(e => <Button style={{ border: `2px solid ${e.color}` }} ghost={pageStore.editorState === e.value} onClick={() => {
                            pageStore.setEditorState(e.value);
                        }} >{e.label}</Button>)}
                    </div>
                    {/* Tileselect */}
                    <h4>EXPORT STUFF</h4>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Button onClick={() => setShowCode(e => !e)}>Toggle Code</Button>
                    </div>

                    {/* Mapselect */}
                    <h4>Maps</h4>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Button onClick={() => {
                            mapLoader.forEach((y, yi) => {
                                (y as any).forEach((x, xi) => {
                                    onChangeData(yi, xi, x);
                                })
                            })
                        }}>
                            load matrix
                        </Button>

                        <Button onClick={() => {
                            setShowImport(true);
                        }} > import matrix </Button>
                    </div>


                </div>
                <div>
                    <span className="detailBadge">Y: {pageStore.sizeY} X: {pageStore.sizeX} <br /></span>
                    <span className="detailBadge">Y: {pageStore.activeTile[0]} X: {pageStore.activeTile[1]} <br /></span>
                    <ButtonGroup>
                        {pageStore.mapData.map((e, index) => {
                            return <Button ghost={index === pageStore.mapPage} onClick={() => (action("changeMap", () => pageStore.mapPage = index))()}>{index}</Button>
                        })}
                        <Button onClick={() => (action("addMap", () => pageStore.mapData = [...pageStore.mapData, pageStore.defaultTiles]))()} icon="add"/>
                    </ButtonGroup>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Grid cb={setUpdate} onChange={onChangeData} />
                    </div>
                </div>
                <div>

                <Tabs id="editPanel" onChange={(newTab) => pageStore.setActivePanel(newTab)} selectedTabId={pageStore.activeTabPanel}>
                    <Tab id="links" title="Items" panel={<MapLinks key={Math.random().toString()}/>} />
                    <Tabs.Expander />
                </Tabs> 
                    
                    {(((pageStore.editorMode === EditorMode.EDITING) && pageStore.activeTile[0] && pageStore.activeTile[1])) &&
                        <>
                            <Button onClick={() => {
                                (action(() => { 
                                    if(pageStore.newMaplink.length === 2) {
                                        pageStore.mapLinks.push([pageStore.newMaplink[0], pageStore.newMaplink[1]]);
                                        pageStore.newMaplink = [];
                                    } else {
                                        pageStore.newMaplink.push({
                                            map: pageStore.mapPage,
                                            pos: pageStore.activeTile,
                                        });
                                    }
                                }))()
                            }}>
                                {pageStore.newMaplink.length < 2 ? "Put down link" : "SAVE LINK"} ({pageStore.newMaplink.map(e => <span>{e.map} - {JSON.stringify(e.pos)}---</span>)});
                            </Button>
                            {!!pageStore.newMaplink.length && <Button onClick={() => (action(() => { pageStore.newMaplink = [] }))()}>CANCEL LINK</Button>}
                        </>
                    }
                </div>
            </div>
            {showCode && <div>
                <textarea style={{width: "800px", height: "800px"}} readOnly
                    value={pageStore.getCode()}
                />
            </div>}
        </>);
})

Editor.defaultProps = {
    x: 11,
    y: 11
}

const MapLinks: React.FC<{fupdate?: () => void}> = observer(({ fupdate }) => {
    const pageStore = React.useContext(editorContext);
    return <>{pageStore.mapLinks.length};{pageStore.mapLinks.map((e, index) => {
        return <React.Fragment key={`${Math.random()}`}><span>{JSON.stringify(e[0])} - {JSON.stringify(e[1])}</span><Button onClick={() => (action(() => { pageStore.mapLinks.splice(index, 1); if(fupdate)fupdate(); } ))()}>DELETE</Button><br/></React.Fragment>
    })}</>
})