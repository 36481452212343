import { useQuery } from "@apollo/client";
import { Box, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@material-ui/core";
import { groupBy } from "lodash";
import * as React from "react";
import { COMMANDS } from "../../querys/q";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className="glass"
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const CommandList: React.FC = () => {
    const { data } = useQuery(COMMANDS);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    if(!data) return <div/>;

    const panels = [];
    const grouped = groupBy(data.commands, e => e.module)
    for(const group in grouped) {
        panels.push(group)
    }

    return <>
      <div className="glass" style={{ marginBottom: 32 }}>
          <Tabs color="primary" variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange}>
            {panels.map((panel, index) =>
                <Tab style={{ color: "white" }} label={panel} key={panel} {...a11yProps(index)} />
            )}
          </Tabs>
      </div>
      <div style={{ padding: 16 }}>
        {panels.map((panel, index) => 
                <TabPanel key={panel} value={value} index={index}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ borderBottom: "1px solid rgb(255 255 255 / 17%)", color: "ghostwhite" }} width="100px">Name</TableCell>
                                    <TableCell style={{ borderBottom: "1px solid rgb(255 255 255 / 17%)", color: "ghostwhite" }} width="300px">Syntax</TableCell>
                                    <TableCell style={{ borderBottom: "1px solid rgb(255 255 255 / 17%)", color: "ghostwhite" }}>Beschreibung</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {grouped[panel].map(command =>
                                    <TableRow key={command.path}>
                                        <TableCell style={{ borderBottom: "1px solid rgb(255 255 255 / 17%)", color: "ghostwhite" }}>{command.name}</TableCell>
                                        <TableCell style={{ borderBottom: "1px solid rgb(255 255 255 / 17%)", color: "ghostwhite" }}>{command.syntax}</TableCell>
                                        <TableCell style={{ borderBottom: "1px solid rgb(255 255 255 / 17%)", color: "ghostwhite" }}>{command.description}</TableCell>
                                    </TableRow>    
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            )
        }
      </div>
    </>
}
