import { useQuery } from "@apollo/client";
import { Container, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import * as React from "react";
import { SHOP } from "../../querys/q";
import { Item } from "../../types";
import { rarityColor } from "../../utils/ItemUtil";



export class ShopState {

}

export const Shop: React.FC = () => {
    return <Container maxWidth="lg" style={{ minHeight: "50vh"}}>
        <Grid spacing={0} style={{  maxHeight: "100vh",  }}>
            <Grid item xs={6} md={3}>
                <ShopList/>
            </Grid>
        </Grid>
    </Container>
}


export const ShopList: React.FC = () => {
    const { data: items , loading}: {data: {shop: Item[]}, loading: boolean} = useQuery(SHOP);

    return <>
        {items?.shop.map(item => <ItemCard item={item}/>)}
    </>
}


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }));
  
const ItemCard: React.FC<{item: Item}> = ({ item }) =>  {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
        <div className="glass" style={{ borderLeft: `8px solid #${(rarityColor[item.rarity] as number).toString(16).padStart(6, "0")}`, padding: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1">
                    {item.name}
                  </Typography>
                  <Typography variant="body2" gutterBottom style={{ fontSize: "0.8em", textTransform: "uppercase"}}>
                    {item.subType}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ fontSize: "0.8em" }}>
                    {item.description}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" style={{ cursor: 'pointer' }}>
                    Add to cart
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">{item.price}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }