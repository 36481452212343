import { useQuery } from "@apollo/client";
import * as React from "react";
import { profileBackgrounds } from "../../querys/q";
import { useStyles } from "../Dashboards/Dashboard";
import * as _ from "lodash";
import { Grid, Paper } from "@material-ui/core";


export const Backgrounds: React.FC = () => {
    const { data, refetch, loading } = useQuery(profileBackgrounds, {
        variables: {
            page: 1,
        }
    });

    const classes = useStyles();

    if(!data?.profileBackgrounds) return <div/>
    const chunks = _.chunk<any>(data.profileBackgrounds, 2);

    

    
    return <Grid direction="column" container spacing={4}>
        {
            chunks.map(tuple => {

                return <Grid item container spacing={4} direction="row">
                    {
                        tuple.map(background => {
                            return background ? <Paper>
                                    <img src={background.imageUrl}/>
                                </Paper> : <></>
                        })
                    }
                    </Grid>
                })
            }
    </Grid>
}
