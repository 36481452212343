import { Background, ItemGrinder, TitlePackItem, ViewState } from "../Types";

import { Steps } from "antd";
import * as React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Formik } from "formik"; 
import { CreateItemFormState, DatabaseContext } from "../State";
import { NEW_BACKGROUND, NEW_GRINDER, NEW_ITEM, NEW_TITLE, NEW_TITLEPACK } from "../../../querys/q";
import { ItemForm, TitlePackForm, GrinderForm, FinalForm } from ".";
import { BackgroundForm } from "./BackgroundForm";

export interface FormikForm {
    id?: number;
    name: string;
    evId: number;
    rarity: number;
    description: string;
    limit: number;
    price: number;
    inShop: boolean;
    subType: string;
    tradeable: boolean;
    titlepack?: Partial<TitlePackItem>;
    grinder?: Partial<ItemGrinder>;
    background?: Partial<Background>;
    // will be used to show our form states
    view: ViewState;
    current: number;

}



export const NewForm: React.FC<{defaults?: FormikForm, onFinish: () => void}> = ({ defaults, onFinish }) => {
    const [ state ] = React.useState(() => new CreateItemFormState());

    const [ addItem ] = useMutation(NEW_ITEM);
    const [ addGrinder ] = useMutation(NEW_GRINDER);
    const [ addTitle ] = useMutation(NEW_TITLE);
    const [ addTitlepack ] = useMutation(NEW_TITLEPACK);
    const [ addBackground ] = useMutation(NEW_BACKGROUND);

    const [initialValues, setInitialValues ] = React.useState<FormikForm>(defaults ? defaults : {
        name: "",
        evId: -1,
        rarity: 0,
        description: "",
        limit: -1,
        price: 1000,
        inShop: false,
        subType: "title",
        tradeable: false,
        // will be used to show our form states
        view: ViewState.Base,
        grinder: {
            amount: 1,
        },
        titlepack: {
            raritySettings: '{"0":2000,"1":1000,"2":1000,"3":1000,"4":1000}'
        },
        current: 0,
        background: {},
    });

    React.useEffect(() => {
        if(defaults) setInitialValues(defaults);
    }, [ defaults ])

    return <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async values => {
            state.setSubmitting(true);
            // we will always do this step
            const d = await addItem({
                variables: {
                    data: {
                        description: values.description,
                        name: values.name,
                        rarity: values.rarity,
                        limit: values.limit,
                        evId: values.evId,
                        price: values.price,
                        inShop: values.inShop,
                        subType: values.subType,
                        tradeable: values.tradeable,
                        id: values.id ?? null,
                    },
                },
                update: (cache, d) => {
                    cache.reset()
                }
            });

            state.setProgress(50);

            // now it depends on the itemsubtype
            const itemId = Number.parseInt(d.data.createItem.id);

            if(["baseItem", "title", "background"].includes(values.subType) &&  values.grinder?.grindedItemId) {
                // we'll setup an grinder for this item
                const g = await addGrinder({
                    variables: {
                        data: {
                            id: itemId,
                            amount: values.grinder.amount,
                            grindedItemId: values.grinder.grindedItemId,
                        }
                    }
                });
                state.setProgress(75);
            }

            if(values.subType === "title") {
                await addTitle({
                    variables: {
                        data: {
                            id: itemId,
                        }
                    }
                })
            } else if(values.subType === "titlepack") {
                await addTitlepack({
                    variables: {
                        data: {
                            ids: values.titlepack.items.map(e => Number.parseInt(e)),
                            id: itemId,
                            raritySettings: values.titlepack.raritySettings,
                        }
                    },
                });
            } else if(values.subType === "background") {
                await addBackground({
                    variables: {
                        id: itemId,
                        imageUrl: values.background.imageUrl,
                    }
                })
            }
            state.setProgress(100);
            state.setFinished(true);
            onFinish();
        }}
    >
        {formik =>
            <div style={{padding: 12 }}>
                <Steps current={formik.values.current} size="small">
                    <Steps.Step onClick={() => {
                        formik.setFieldValue("current", 0);
                        formik.setFieldValue("view", ViewState.Base);
                    }} title="BaseItem" />
                    <Steps.Step onClick={() => {
                        formik.setFieldValue("current", 1);
                        formik.setFieldValue("view", ViewState.Titlepack);
                    }} title="Titlepack" />
                    <Steps.Step  onClick={() => {
                        formik.setFieldValue("current", 3);
                        formik.setFieldValue("view", ViewState.Grinder);
                    }} title="Grinder" />
                    <Steps.Step  onClick={() => {
                        formik.setFieldValue("current", 2);
                        formik.setFieldValue("view", ViewState.Background);
                    }} title="Background" />
                    <Steps.Step title="Creating Item" icon={state.submitting ? <LoadingOutlined /> : null} />
                </Steps>
                <DatabaseContext.Provider value={state}>
                    {formik.values.view === ViewState.Base && <ItemForm/>}
                    {formik.values.view === ViewState.Titlepack && <TitlePackForm/>}
                    {formik.values.view === ViewState.Grinder && <GrinderForm/>}
                    {formik.values.view === ViewState.Background && <BackgroundForm/>}
                    {formik.values.view === ViewState.FinalScreen && <FinalForm onFinish={onFinish}/>}
                </DatabaseContext.Provider>
            </div>
        }
    </Formik>
}

