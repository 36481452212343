export enum RARITY {
    UNIQUE = -1,
    GEWOEHNLICH,
    UNGEWOEHNLICH,
    SELTEN,
    EPISCH,
    SHINY,
    MEISTERHAFT
}

export const rarityColor = {
    [RARITY.UNIQUE]: 0,
    [RARITY.GEWOEHNLICH]: 16777215,
    [RARITY.UNGEWOEHNLICH]: 3329330,
    [RARITY.SELTEN]: 131166,
    [RARITY.EPISCH]: 1835321,
    [RARITY.SHINY]: 9829615,
    [RARITY.MEISTERHAFT]: 15660125,
}

export const rarityName = {
    [RARITY.UNIQUE]: "Unique",
    [RARITY.GEWOEHNLICH]: "Gewöhnlich",
    [RARITY.UNGEWOEHNLICH]: "Ungewöhnlich",
    [RARITY.SELTEN]: "Selten",
    [RARITY.EPISCH]: "Episch",
    [RARITY.SHINY]: "Shiny",
    [RARITY.MEISTERHAFT]: "Meisterhaft",
}

export function renderItemname(name: string, rarity: RARITY) {
    switch(rarity) {
        case RARITY.EPISCH: return `**_${name}_**` 
        case RARITY.GEWOEHNLICH: return `${name}` 
        case RARITY.UNGEWOEHNLICH: return `_${name}_` 
        case RARITY.SELTEN: return `**${name}**` 
        case RARITY.SHINY: return `\`${name}\``  
        case RARITY.UNIQUE: return `**${name}**` 
        case RARITY.MEISTERHAFT: return `**__${name}__**` 
    }
}

export function renderItemnameCSS(name: string, rarity: RARITY) {
    switch(rarity) {
        case RARITY.EPISCH: return `<span style="font-style: italic;font-weight: 900;">${name}</span>`;
        case RARITY.GEWOEHNLICH: return `<span>${name}</span>`;
        case RARITY.UNGEWOEHNLICH: return `<span style="font-style: italic;">${name}</span>`;
        case RARITY.SELTEN: return `<span style="font-weight: 900;">${name}</span>`;
        case RARITY.SHINY: return `<span style="border: 5px solid #${(9829615).toString(16).padStart(6, "0")}; background: ghostwhite; color: black;padding: 4px;font-family: Lucida Console;text-shadow: 1px 1px 2px white, 0 0 25px #${(9829615).toString(16).padStart(6, "0")}, 0 0 5px teal;">${name}</span>`;
        case RARITY.UNIQUE: return `<span style="outline: 3px soild steelblue;border: 2px solid hotpink; background: ghostwhite; color: black">${name}</span>`;
        case RARITY.MEISTERHAFT: return `<span style="border: 5px solid red; background: ghostwhite; color: black;padding: 4px;font-family: Lucida Console;text-shadow: 1px 1px 2px black, 0 0 25px red, 0 0 5px yellow;">${name}</span>`;
    }
}