import React from 'react';
import './App.css';
import 'antd/dist/antd.css';


import { Dashboard } from './app/Dashboards/Dashboard';
import { observer } from "mobx-react";


import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { orange, red, blue } from '@material-ui/core/colors';

const theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        main: '#1d1c1c',
        contrastText: "white",
        light: "white"
      },
      secondary: {
        main: "#b71c1c",
        contrastText: "white"
      },
      background: {
        paper: "#1d1c1c",
      },
      text: {
        primary: "#FFFFFF",
        hint: "steelblue",
        secondary: "ghostwhite"
      }
    },
  });







const App: React.FC = observer(() => {
    return (
        <>
            <div className="auroaUIContainer">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <ThemeProvider theme={theme}>
                <Dashboard/>
            </ThemeProvider>
        </>
    );
})
export default App;
