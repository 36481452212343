

import { Button, Col, Row, Form, Input, Select, Switch, InputNumber } from "antd";
import * as React from "react";
import { useFormikContext } from "formik"; 
import { FormikForm } from "./BaseForm";
import { rarityName } from "../../../utils/ItemUtil";
import { ViewState } from "../Types";









export const ItemForm: React.FC = () => {
    const formik = useFormikContext<FormikForm>();
    const gutter = 16 + 8*3;


    return <Form layout="vertical">
        <div style={{ padding: 8 }}>
            <Row gutter={gutter}>
                <Col xs={3}>
                    <Form.Item label="Name">
                        <Input value={formik.values.name} onChange={e => formik.setFieldValue("name", e.target.value)}/>
                    </Form.Item>
                </Col>
                <Col xs={3}>
                    <Form.Item label="Item Type">
                        <Select
                            disabled={!!formik.values.id}
                            placeholder="Select a option and change input text above"
                            value={formik.values.subType}
                            onChange={e => formik.setFieldValue("subType", e)}
                        >
                            <Select.Option value="title">Title</Select.Option>
                            <Select.Option value="baseItem">BaseItem</Select.Option>
                            <Select.Option value="titlepack">Titlepack</Select.Option>
                            <Select.Option value="background">Background</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={3}>
                    <Form.Item label="Rarity">
                        <Select
                            placeholder="Select a option and change input text above"
                            value={formik.values.rarity}
                            onChange={e => formik.setFieldValue("rarity", e)}
                        >
                        {[-1, 0, 1, 2, 3, 4, 5].map(e => {
                            return <Select.Option value={e}>{rarityName[e]}</Select.Option>
                        })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={3}>
                    <Form.Item
                        label="Price"
                    >
                        <InputNumber value={formik.values.price} onChange={e => formik.setFieldValue("price", e)}/>
                    </Form.Item>
                </Col>
                <Col xs={3}>
                    <Form.Item
                        label="Limit"
                        tooltip="-1 means, that there is no limit"
                    >
                        <InputNumber value={formik.values.limit} onChange={e => formik.setFieldValue("limit", e)}/>
                    </Form.Item>
                </Col>
                <Col xs={3}>
                    <Form.Item label="In Shop">
                        <Switch defaultChecked={formik.values.inShop} onChange={e => formik.setFieldValue("inShop", e)}/>
                    </Form.Item>
                </Col>
                <Col xs={3}>
                    <Form.Item label="Tradeable">
                        <Switch defaultChecked={formik.values.tradeable} onChange={e => formik.setFieldValue("tradeable", e)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Form.Item label="Description">
                        <textarea style={{ width: "100%" }} rows={4} value={formik.values.description} onChange={e => formik.setFieldValue("description", e.target.value)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Button onClick={() => {
                if(formik.values.subType === "titlepack") {
                    formik.setFieldValue("view", ViewState.Titlepack);
                    formik.setFieldValue("current", 1);
                } else if(formik.values.subType === "background") {
                    formik.setFieldValue("view", ViewState.Background);
                    formik.setFieldValue("current", ViewState.Background);
                } else {
                    formik.setFieldValue("view", ViewState.Grinder);
                    formik.setFieldValue("current", ViewState.Grinder);
                }
            }}>Next</Button>
        </div>
    </Form>
}