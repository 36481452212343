import { makeObservable, observable, action, computed } from "mobx";
import jwt from "jsonwebtoken";

export class AppStore {
    public token = "";

    public get user() {
        return jwt.decode(this.token);
    }


    constructor() {

        makeObservable(this, {
            getToken: action,
            setUser: action,
            token: observable,
            user: computed,
        })
        
        this.getToken();

    }

    public setUser(token: string) {
        this.token = token;
        localStorage.setItem("token", token);
    }


    public getToken() {
        this.token = localStorage.getItem("token");
    }

    public logout() {
        localStorage.removeItem("token");
        this.token = null;
    }
}