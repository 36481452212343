import { Avatar, Menu, MenuItem } from "@material-ui/core";
import * as React from "react";
import { AppStoreCtx } from "..";



export const AvatarMenu: React.FC = () => {
    const ctx = React.useContext(AppStoreCtx);

    const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState()

    const recordButtonPosition = (event: any) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    }

    const logout = () => {
        setMenuOpen(false);
        ctx.logout();
    }

    return <>
    <Avatar onClick={recordButtonPosition} src={`https://cdn.discordapp.com/avatars/${ctx.user.dcUser.id}/${ctx.user.dcUser.avatar}`}/>
    <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        onClose={setMenuOpen.bind(this, false)}
        >
        <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
    </>
}