import { observable, action, reaction, computed, makeObservable } from "mobx";
import { FieldType } from "./Editor";
import * as React from "react";

export enum EditorMode {
    DRAWING = 0,
    EDITING = 1,

}


export interface ExactLocation {
  map: number;
  pos: [number, number]
}


export type MapLink = [ ExactLocation, ExactLocation ];

export function* buildLink(pos: ExactLocation) {
  let nextLink: MapLink = [pos, null];
  yield null;
  nextLink[1] = pos;
  yield nextLink;
}

export const editorContext = React.createContext<EditorState>(null)

export class EditorState {   
  public editorMode = EditorMode.DRAWING;
  public editorState = FieldType.HOUSE;
  public changes = new Map<string, any>()
  public pos;
  public sizeX = 14;
  public sizeY = 14;
  public activeTile: [number, number] = [null, null];
  public newMaplink: ExactLocation[] = [];
  public mapPage = 0;
  public mapLinks: MapLink[] = [];
  public mapData = [ this.defaultTiles ];
  public movement = [];
  public mouseDown = false;
  public brush = 1;
  public maps = [];
  public mapName = "Default";
  public items = [];
  public activeTabPanel: React.ReactText = "links";
  public drawing = false;
  public ctx: CanvasRenderingContext2D; 


  private registredViews = [];

  constructor() {
    reaction(
      () => `${this.mapPage}-${this.activeTile.join("-")}`,
      () => {
        if(this.map[this.activeTile[0]][this.activeTile[1]] === FieldType.ITEMCHEST) {
          this.setActivePanel("chest")
        }
      } 
    )

    makeObservable(this, {
      editorMode: observable,
      editorState: observable,
      changes: observable,
      pos: observable,
      sizeX: observable,
      sizeY: observable,
      activeTile: observable,
      newMaplink: observable,
      mapPage: observable,
      mapLinks: observable,
      mapData: observable,
      movement: observable,
      mouseDown: observable,
      brush: observable,
      maps: observable,
      mapName: observable,
      items: observable,
      activeTabPanel: observable,
      drawing: observable,
      ctx: observable,

      setActivePanel: action.bound,
      reset: action.bound,
      setMapData: action.bound,
      logMapData: action.bound,
      setEditorState: action.bound,
      setEditorMode: action.bound,
      setActiveTile: action.bound,
      updateMap: action.bound,

      map: computed,
    })
  }


 public get map() {
    return this.mapData[this.mapPage];
  }

  public setActivePanel(key: React.ReactText) {
    this.activeTabPanel = key;
  }

  public reset() {
    this.mapData = [this.defaultTiles];
  }

  public setMapData(y, x, type) {
      this.mapData[this.mapPage][y][x] = type;
  }

  

  public logMapData() {
      console.log(JSON.stringify(this.mapData));
  }

  public setEditorState(a) {
    this.editorState = a;
  }

  public setEditorMode(a: EditorMode) {
    this.editorMode = a;
  }

  public setActiveTile(a) {
    this.activeTile = a;
  }

  public get defaultTiles() {
      // default tiles using sizes X and Y -> returns empty map
      let ret = [];
      for(let x = 0; x < this.sizeX; x++) {
        let xRow = [];
        for(let y = 0; y < this.sizeY; y++) {
          if(x === 0 || y === 0 || y === (this.sizeY - 1) || x === (this.sizeX - 1)) {
            xRow.push(1); // map border
          } else {
            
            if(y === 3 && x === 4) {
              xRow.push(3)
            } else if (x === 1 && y === 0) {
              xRow.push(4);
            } else if(y === 40 && x === 40) {
              xRow.push(4)
            } else {
              xRow.push(FieldType.NOTHING);
            }
          }
        }
        ret.push(xRow);
      }
      return ret;
    }

    public static emptySet(x, y) {
      // default tiles using sizes X and Y -> returns empty map
      let ret = [];
      for(let x = 0; x < x; x++) {
        let xRow = [];
        for(let y = 0; y < y; y++) {
          xRow.push(1);
        }
        ret.push(xRow);
      }
      return ret;
    }

  public getCode() {
    let mapObj = {
      "maps": {},
      "links": this.mapLinks.map(e =>  ([
        {map: e[0].map, pos: [e[0].pos[1], e[0].pos[0]]},
        {map: e[1].map, pos: [e[1].pos[1], e[1].pos[0]]}
      ])),
    }
    this.mapData.forEach((e, index) => {
      // mapObj += `"${index}": ${JSON.stringify(e)},`
      mapObj["maps"][index] = e;
    })
    return JSON.stringify(mapObj);
  }

  public forceUpdate() {
    this.registredViews.forEach(e => {
      if(e.animationHandler) {
        e.animationHandler.updateMap();
      } else {
        e.updateMap();
      }
    });
  }

  public updateMap(mapData) {
    this.mapData = mapData;
    this.forceUpdate();
  }

}