import { useQuery } from "@apollo/client";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import * as React from "react";
import { AppStoreCtx } from "..";
import { INVENTORY } from "../querys/q";
import { rarityName } from "../utils/ItemUtil";


export const InventoryItemList: React.FC<{id?: number}> = ({ id }) => {
    const ctx = React.useContext(AppStoreCtx);
    const { data, refetch, loading } = useQuery(INVENTORY, {
        variables: {
            id: id ?? ctx.user.profileId
        }
    });

    React.useEffect(() => {
        refetch();
    }, [ id ])
    if(loading) return <div></div>
    return <div className="glass"> <TableContainer>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Rarity</TableCell>
                    <TableCell>Name</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.getInventory.map((d) =>
                    <TableRow key={d.id}>
                        <TableCell>{rarityName[d.item.rarity]}</TableCell>
                        <TableCell>{d.item.name}</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </TableContainer>
    </div>
}