

import { Button, Col, Row, Form, Transfer } from "antd";
import * as React from "react";
import { observer } from "mobx-react";
import { useFormikContext } from "formik"; 
import { FormikForm } from "./BaseForm";
import { DatabaseContext } from "../State";
import { ViewState } from "../Types";
import { useItemDB } from "../../../hooks";
import { rarityName } from "../../../utils/ItemUtil";









export const TitlePackForm: React.FC = observer(() => {
    const formik = useFormikContext<FormikForm>();
    const gutter = 16 + 8*3;

    const state = React.useContext(DatabaseContext); 

    const [ loading, items ] = useItemDB(-1);

    return <Form layout="vertical">
        <div style={{ padding: 8 }}>
            <Row gutter={gutter}>
                <Col xs={24}>
                    <Transfer
                        dataSource={items.items}
                        titles={['Items', 'Titlepack']}
                        targetKeys={formik.values.titlepack.items}
                        showSearch
                        rowKey={e => {
                            return e.id;
                        }}

                        listStyle={{
                            width: "100%",
                            height: 500,
                        }}

                        selectedKeys={state.selectedKeys}

                        filterOption={(e, item) => item.name.includes(e)}
                        onChange={e => formik.setFieldValue("titlepack.items", e)}
                        onSelectChange={(source, target) => {
                            state.setSelectedKeys([...source, ...target])
                        }}
                        pagination={{
                            pageSize: 10
                        }}
                        render={item => `${item.name} - ${item.subType} - ${rarityName[item.rarity]}`}
                        style={{ marginBottom: 16 }}
                    />
                </Col>
            </Row>
            <Row gutter={gutter}>
                <Col xs={12}>
                    <Form.Item label="Rarity Settings">
                        <textarea style={{ width: "100%" }} rows={4} value={formik.values.titlepack.raritySettings} onChange={(e) => formik.setFieldValue("titlepack.raritySettings", e.target.value)}/>
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    {state.calculateRarity(formik.values.titlepack.raritySettings).map(e => {
                        return <p>{rarityName[Number.parseInt(e.key)] ?? "UNKNOWN"}:&nbsp;{e.chance}%</p>
                    })}
                </Col>
            </Row>
            <Button onClick={() => {
                formik.setFieldValue("view", ViewState.FinalScreen);
                formik.setFieldValue("current", 4);
            }}>Next</Button>
        </div>
    </Form>
})
