import { Avatar, Container, Grid, Paper } from "@material-ui/core";
import * as React from "react";
import { AppStoreCtx } from "../..";



export const Profile: React.FC<{id?: number}> = ({id}) => {

    const ctx = React.useContext(AppStoreCtx);

    return <div className="glass">
        <Container maxWidth="lg" style={{ minHeight: "50vh"}}>
            <Grid spacing={2} style={{  maxHeight: "100vh",  }}>
                <Grid item xs={4}>
                    <Avatar variant="rounded" style={{ width: "128px", height: "128px" }} imgProps={{ width: "128px", height: "128px" }} src={`https://cdn.discordapp.com/avatars/${ctx.user.dcUser.id}/${ctx.user.dcUser.avatar}`}/>
                </Grid>
            </Grid>
        </Container>
    </div>
}