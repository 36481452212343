

import { Button, Col, Row, Form, Input, Image } from "antd";
import * as React from "react";
import { useFormikContext } from "formik"; 
import { FormikForm } from "./BaseForm";
import { ViewState } from "../Types";









export const BackgroundForm: React.FC = () => {
    const formik = useFormikContext<FormikForm>();
    const gutter = 16 + 8*3;

    return <Form layout="vertical">
        <div style={{ padding: 8 }}>
            <Row gutter={gutter}>
                <Col xs={3}>
                    <Form.Item label="IMAGE URL">
                        <Input value={formik.values.background.imageUrl} onChange={e => formik.setFieldValue("background.imageUrl", e.target.value)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={gutter}>
                <Col xs={24}>
                    <Image src={formik.values.background.imageUrl}/>
                </Col>
            </Row>
            <Button onClick={() => {
                let nextView = ViewState.FinalScreen;
                formik.setFieldValue("view", nextView);
                formik.setFieldValue("current", 4);
            }}>Next</Button>
        </div>
    </Form>
}