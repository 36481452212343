import { Button, ButtonGroup } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router";

export const DashboardButtonBar: React.FC = () => {
    const history = useHistory();
    return <ButtonGroup color="secondary" variant="outlined">
    <Button onClick={() => history.push("/commands")} size="small">
        Commands
    </Button>
    <Button onClick={() => history.push("/titlepacks")} size="small">
        Titelpacks
    </Button>
    {/** <Button onClick={() => history.push("/backgrounds")} size="small">
        Backgrounds
    </Button> **/}
</ButtonGroup>
}