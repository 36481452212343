
import { Button, Result, Progress } from "antd";
import * as React from "react";
import { observer } from "mobx-react";
import { CheckCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useFormikContext } from "formik"; 
import { FormikForm } from "./BaseForm";
import { DatabaseContext } from "../State";







export const FinalForm:  React.FC<{onFinish: () => void}> = observer(({ onFinish })  => {
    const formik = useFormikContext<FormikForm>();
    const state = React.useContext(DatabaseContext);

    return <div>
        {!state.submitting && (!state.isFinished ? <Result
            icon={<QuestionCircleOutlined />}
            title="Bist du dir sicher, dass du dieses Item erstellen willst?"
            extra={
            <Button onClick={() => formik.handleSubmit()} type="primary" key="console">
                JA!
            </Button>
            }
        /> : <Result
        icon={<CheckCircleOutlined />}
        title="Bist du dir sicher, dass du dieses Item erstellen willst?"
        extra={
        <Button onClick={() => {
            onFinish();
        }} type="primary" key="console">
            Nice!
        </Button>
        }
    />)}
    {state.submitting && 
        <Result
            icon={<Progress
                type="circle"
                percent={state.progress}
            />}
            title="Ok, machen wir!"
        />}
    </div>
});
