
import { Button, Col, Row, Table, Form, Input, InputNumber, Space } from "antd";
import * as React from "react";
import { observer } from "mobx-react";
import {  SearchOutlined } from "@ant-design/icons";
import { useFormikContext } from "formik"; 
import { FormikForm } from "./BaseForm";
import { DatabaseContext } from "../State";
import { useItemDB } from "../../../hooks";
import { rarityName } from "../../../utils/ItemUtil";
import { ViewState } from "../Types";










export const GrinderForm: React.FC = observer(() => {
    const formik = useFormikContext<FormikForm>();
    const gutter = 16 + 8*3;

    const state = React.useContext(DatabaseContext); 

    const [ loading, items ] = useItemDB(-1);

   const [ searchText , setSearchText] = React.useState();
    return <Form layout="vertical">
        <div style={{ padding: 8 }}>
            <Row gutter={gutter}>
                <Col xs={3}>
                    <Form.Item
                        label="Amount"
                    >
                        <InputNumber value={formik.values.grinder.amount} onChange={e => formik.setFieldValue("grinder.amount", e)}/>
                    </Form.Item>
                </Col> 
            </Row>
            <Row gutter={gutter}>
                <Col xs={24}>
                    <Table rowKey={e => e.id} size="small" loading={loading} dataSource={items?.items ?? []}
                        rowSelection={{
                            onSelect: (record, selected, selectedRows) => {
                                formik.setFieldValue("grinder.grindedItemId", Number.parseInt(record.id));
                            },
                            selectedRowKeys: formik.values?.grinder?.grindedItemId ? [formik.values.grinder.grindedItemId.toString()] : [],
                            hideSelectAll: true,
                            type: "radio",
                            onChange: (...e) => console.log(e),
                        }}
                        columns={[{
                            title: "Name",
                            key: "name",
                            dataIndex: "name",
                            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                <div style={{ padding: 8 }}>
                                    <Input
                                        value={selectedKeys[0]}
                                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                                    />
                                    <Space>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                confirm();
                                                setSearchText(selectedKeys[0] as any);
                                            }}
                                            icon={<SearchOutlined />}
                                            size="small"
                                            style={{ width: 90 }}
                                        >
                                            Search
                                        </Button>
                                        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                                            Reset
                                        </Button>
                                    </Space>
                                </div>
                            ),
                            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
                            onFilter: (value, record) =>
                                record.name
                                    ? record.name.toLowerCase().includes(value?.toString().toLowerCase())
                                    : false,
                        }, {
                            title: "Rarity",
                            filters: [-1, 0, 1, 2, 3, 4, 5].map(e => ({
                                text: rarityName[e],
                                value: e,
                            })),
                            onFilter: (value, record) => record.rarity === value,
                            key: "rarity",
                            dataIndex: "rarity",
                            render: (e) => rarityName[e]
                        }, {
                            defaultFilteredValue: [ "baseitem" ],
                            filters: ["baseitem", "title", "titlepack", "background"].map(e => ({
                                text: e,
                                value: e,
                            })),
                            onFilter: (value, record) => record.subType === value,
                            title: "Subtype",
                            key: "subType",
                            dataIndex: "subType",
                        }]}
                    />
                </Col> 
            </Row>
            <Button onClick={() => {
                formik.setFieldValue("view", ViewState.FinalScreen);
                formik.setFieldValue("current", 4);
            }}>Next</Button>
        </div>
    </Form>
});
