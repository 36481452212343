

import { Button, Col, Descriptions, PageHeader, Row, Table, Input, Switch, Space, Menu, Dropdown, Popconfirm } from "antd";
import * as React from "react";
import { useItemDB } from "../../hooks";
import { Item } from "../../types";
import { rarityName } from "../../utils/ItemUtil";
import { CopyOutlined, EditOutlined, MoreOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { SET_SHOP } from "../../querys/q";
import { FormikForm, NewForm } from "./forms";






export const ItemDatabase: React.FC<{ onChange?: (item: Item) => void }> = ({ onChange }) => {
    const [ loading, items, setPage ] = useItemDB(-1)
    const [ activeItem, setActiveItem ] = React.useState<Item>();
    const [ createItem, setCreateItem ] = React.useState<"new" | "copy" | "edit">();

    const q = useQuery(
        gql`
            query ItemData($id: Float!) {
                getTitlepack(id: $id) {
                    id
                    raritySettings
                    items {
                        id
                    }
                }

                getGrinder(id: $id) {
                    id
                    amount
                    grindedItemId
                }

                getTitle(id: $id) {
                    id
                }

                getItem(id: $id) {
                    id,
                    description,
                    name,
                    rarity,
                    limit,
                    evId,
                    price,
                    subType,
                    tradeable,
                    inShop
                }
            }
        `, {
            variables: {
                id: Number.parseInt(activeItem?.id),
            }
        }
    )


    
    const [ setShop ] = useMutation(SET_SHOP);

    const [searchText, setSearchText] = React.useState();

    const [ defaultValues, setDefaultValues ] = React.useState<FormikForm>();

    React.useEffect(() => {
        if(q.loading || activeItem == null) return;
        const { getTitlepack, getGrinder, getTitle, getItem } = q.data;
        setDefaultValues({
            id: createItem === "copy" ? null : Number.parseInt(getItem.id),
            name: getItem.name,
            evId: getItem.evId,
            rarity: getItem.rarity,
            description: getItem.description,
            limit: getItem.limit,
            price: getItem.price,
            inShop: getItem.inShop,
            subType: getItem.subType,
            tradeable: getItem.tradeable,
            // will be used to show our form states
            view: 0,
            grinder: {
                amount: getGrinder?.amount ?? 0,
                grindedItemId: getGrinder?.grindedItemId
            },
            titlepack: {
                raritySettings: getTitlepack?.raritySettings,
                items: getTitlepack?.items.map(e => e.id),
            },
            current: 0,
        })
    }, [ q.data ]);

    return <>
        <PageHeader
            title="Item DB"
            extra={[
                <Button onClick={() => setCreateItem(e => {
                    if(e) return null;
                    else {
                        setActiveItem(null);
                        setDefaultValues(null);
                        return "new"

                    }
                })}>+</Button>
            ]}
        />
        <Row style={{ width: "100%" }}>
            <Col span={createItem ? 0 : 24}>
                <Table rowKey={e => e.id} size="small" loading={loading} dataSource={items?.items ?? []}

                    columns={[{
                        title: "Name",
                        key: "name",
                        dataIndex: "name",
                        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                            <div style={{ padding: 8 }}>
                                <Input
                                    value={selectedKeys[0]}
                                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                                />
                                <Space>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            confirm();
                                            setSearchText(selectedKeys[0] as any);
                                        }}
                                        icon={<SearchOutlined />}
                                        size="small"
                                        style={{ width: 90 }}
                                    >
                                        Search
                                </Button>
                                    <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                                        Reset
                                </Button>
                                </Space>
                            </div>
                        ),
                        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
                        onFilter: (value, record) =>
                            record.name
                                ? record.name.toLowerCase().includes(value?.toString().toLowerCase())
                                : false,
                    }, {
                        title: "Rarity",
                        filters: [-1, 0, 1, 2, 3, 4, 5].map(e => ({
                            text: rarityName[e],
                            value: e,
                        })),
                        onFilter: (value, record) => record.rarity === value,
                        key: "rarity",
                        dataIndex: "rarity",
                        render: (e) => rarityName[e]
                    }, {
                        title: "Description",
                        key: "description",
                        dataIndex: "description",
                    }, {
                        filters: ["baseitem", "title", "titlepack", "background"].map(e => ({
                            text: e,
                            value: e,
                        })),
                        onFilter: (value, record) => record.subType === value,
                        title: "Subtype",
                        key: "subType",
                        dataIndex: "subType",
                    }, {
                        title: "In Shop",
                        key: "inShop",
                        filters: [true, false].map(e => ({
                            text: `${e}`,
                            value: e,
                        })),
                        onFilter: (value, record) => record.inShop === value,
                        render: (value) => <Switch defaultChecked={value.inShop} onChange={e => {
                            console.log(value)
                            setShop({
                                variables: {
                                    id: Number.parseInt(value.id),
                                    inShop: e
                                }
                            })
                        }} />
                    }, {
                        title: "Actions",
                        key: "actions",
                        render: (data) => {
                            return <Dropdown trigger={["click"]} overlay={<Menu>
                                <Menu.Item onClick={() => {
                                    setActiveItem(data);
                                    setCreateItem("edit");
                                }}>
                                    <EditOutlined /> Edit
                                </Menu.Item>
                                <Menu.Item onClick={() => {
                                    setActiveItem(data);
                                    setCreateItem("copy");
                                }}>
                                    <CopyOutlined /> Copy
                                </Menu.Item>
                                <Menu.Item danger>
                                    <Popconfirm
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        title="Are you sure to delete this item?"
                                        onConfirm={() => alert("WILL DELETE")}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <a href="#">Delete</a>
                                    </Popconfirm>
                                </Menu.Item>
                            </Menu>}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <MoreOutlined />
                                </a>
                            </Dropdown>
                        }
                    }, ]}
                />
            </Col>
            {createItem && <Col span={24} style={{ background: "white" }}>
            <NewForm defaults={defaultValues} onFinish={() => {
                setCreateItem(null);
                setActiveItem(null);
            }} />;
            </Col>}
        </Row>
    </>
}
