import { useQuery } from "@apollo/client";
import { ITEMS } from "../querys/q";
import * as React from "react";
import { Item } from "../types";
import * as _ from "lodash-es";

export function useItemDB(startPage = 0): [ boolean, {items: Item[]}, React.Dispatch<React.SetStateAction<number>>] {
    const [ page, setPage ] = React.useState(startPage);
    const q = useQuery(ITEMS, {
        variables: {
            page
        }
    });

    React.useEffect(() => {
        if(q.loading) return;
        if(q?.refetch) q.refetch();
    }, [ page ]);


    return [ q.loading, _.cloneDeep(q.data), setPage ]
}