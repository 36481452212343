import { useQuery } from "@apollo/client";
import { Box, Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@material-ui/core";
import { groupBy } from "lodash";
import * as React from "react";
import { COMMANDS, TITLEPACKS } from "../../querys/q";
import { rarityColor, rarityName } from "../../utils/ItemUtil";
import { useStyles } from "../Dashboards/Dashboard";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className="glass"
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const Titlepacks: React.FC = () => {
    const { data, refetch, loading } = useQuery(TITLEPACKS, {
        variables: {
            page: 1,
        }
    });

    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    if(!data) return <div/>;

    function calculateRarity(str: string) {
        try {
            const set = JSON.parse(str);

            let max = 0;
            let len = 0;
            for (const i in set) {
                max += set[i];
                len++;
            }

            const res = []
            for (let i in set) {
                res.push({
                    key: i,
                    chance: set[i] / (max / 100)
                })
            }
            return res;
        } catch (err) {
            return [];
        }
    }

    if(!data?.getTitlepacks) return <div/>

    
    return <>
      <div className="glass fade-in" style={{ marginBottom: 32 }}>
          <Tabs color="primary" variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange}>
            {data.getTitlepacks.map((panel, index) =>
                <Tab style={{ color: "white" }} label={panel.item.name} key={panel.id} {...a11yProps(index)} />
            )}
          </Tabs>
      </div>
      <div className="fade-in" style={{ padding: 16 }}>
        {
            data.getTitlepacks.map((panel, index) => {
                const settings = JSON.parse(panel.raritySettings);
                return <TabPanel value={value} index={index}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <Paper style={{ flexGrow: 1, flexShrink: 1, width: "50%", padding: 12 }}>
                            <TableContainer style={{ maxHeight: "70vh", borderRadius: "1%" }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ borderBottom: "1px solid rgb(255 255 255 / 17%)", color: "ghostwhite" }} width="200px">Name</TableCell>
                                            <TableCell style={{ borderBottom: "1px solid rgb(255 255 255 / 17%)", color: "ghostwhite" }} width="200px">Seltenheit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {panel.items.filter(item => settings[item.rarity]).sort((a, b) => a.rarity - b.rarity).map(item =>
                                            <TableRow key={item?.id}>
                                                <TableCell style={{ borderBottom: "1px solid rgb(255 255 255 / 17%)", color: "ghostwhite" }}><div className="">{item?.name}</div></TableCell>
                                                <TableCell style={{ borderBottom: "1px solid rgb(255 255 255 / 17%)", color: "ghostwhite" }}>{rarityName[item?.rarity]}</TableCell>
                                            </TableRow>    
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <div style={{ flexGrow: 1, flexShrink: 1, width: "50%" }}>
                            <Paper elevation={4} style={{ margin: 8, padding: 12 }}>
                                <Grid container spacing={3} direction="column">
                                    <Grid item>
                                        <Typography component="h1" variant="h4" color="textPrimary" noWrap className={classes.title}>
                                            {panel.item.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography component="div" variant="body1" color="textSecondary" noWrap className={classes.title}>
                                        <p>{panel.item.inShop && "Im Shop!"}</p>
                                        <p>Preis: {panel.item.price} Fluffs</p>
                                        <p>{panel.item.description && panel.item.description}</p>
                                        {calculateRarity(panel.raritySettings).map(obj => {
                                            return <p>{rarityName[Number.parseInt(obj.key)]}: {obj.chance}%</p>
                                        })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </div>
                </TabPanel>

            })
        }
      </div>
    </>
}
